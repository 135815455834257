import fetch from "cross-fetch";
import { z } from "zod";

/**
 * MSW currently does not support Node18, but we opted to lock the
 * dashboard repo to this version anyway to be the most future proof.
 * This cross-fetch package fills the gap so that we can have both.
 *
 * TODO: Remove this as soon as MSW supports Node 18.
 * https://github.com/mswjs/msw/issues/1388
 */

import getEndpointUrl from "./environment/getEndpointUrl";
import getHeaders from "./getHeaders";
import getUnexpectedResponseError from "./getUnexpectedResponseError";
import prepareRequestData from "./prepareRequestData";
import prepareResponseData from "./prepareResponseData";
import AuthConfig from "./types/AuthConfig";
import JsonData from "./types/JsonData";
import JsonDataZodSchema from "./types/JsonDataZodSchema";

export enum HttpMethod {
  Delete = "DELETE",
  Get = "GET",
  Patch = "PATCH",
  Post = "POST",
  Put = "PUT",
}

type MakeRequestOptions<
  TRequestDataSchema extends JsonDataZodSchema | z.ZodType<FormData>,
  TResponseDataSchema extends JsonDataZodSchema,
> = AuthConfig & {
  method: HttpMethod;
  urlPath: string;
  urlSearchParams?: URLSearchParams;
  requestData?: JsonData | FormData;
  requestDataSchema?: TRequestDataSchema;
  responseDataSchema?: TResponseDataSchema;
  /**
   * Whether the given request needs to be authenticated with the access token
   * obtained via the given `getAccessToken` method. Defaults to true.
   */
  isAuthenticated?: boolean;
};

export const doFetch = async ({
  url,
  method,
  headers,
  body,
}: {
  url: string;
  method: HttpMethod;
  headers?: Headers;
  body?: RequestInit["body"];
}) => {
  try {
    return await fetch(url, {
      method,
      headers,
      body,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error("Unable to establish connection to server.");
  }
};

// Overload: If no responseDataSchema is given, no response data will be returned.
function makeRequest<
  TRequestDataSchema extends JsonDataZodSchema | z.ZodType<FormData>,
  TResponseDataSchema extends JsonDataZodSchema,
>({
  getAccessToken,
  environment,
  method,
  urlPath,
  urlSearchParams,
  requestData,
  requestDataSchema,
  isAuthenticated,
}: Omit<
  MakeRequestOptions<TRequestDataSchema, TResponseDataSchema>,
  "responseDataSchema"
>): Promise<void>;

// Overload: If responseDataSchema is given, typed response data will be returned.
function makeRequest<
  TRequestDataSchema extends JsonDataZodSchema | z.ZodType<FormData>,
  TResponseDataSchema extends JsonDataZodSchema,
>({
  getAccessToken,
  environment,
  method,
  urlPath,
  urlSearchParams,
  requestData,
  requestDataSchema,
  responseDataSchema,
  isAuthenticated,
}: MakeRequestOptions<TRequestDataSchema, TResponseDataSchema>): Promise<
  z.infer<TResponseDataSchema>
>;

/**
 * Makes a request to TaxBit servers. This process includes validation for data
 * being sent to and from the server as well as casing conversion.
 */
async function makeRequest<
  TRequestDataSchema extends JsonDataZodSchema,
  TResponseDataSchema extends JsonDataZodSchema,
>({
  getAccessToken,
  environment,
  method,
  urlPath,
  urlSearchParams,
  requestData,
  requestDataSchema,
  responseDataSchema,
  isAuthenticated = true,
}: MakeRequestOptions<TRequestDataSchema, TResponseDataSchema>): Promise<
  z.infer<TResponseDataSchema>
> {
  const preparedRequestData =
    requestData instanceof FormData
      ? requestData
      : prepareRequestData({
          requestData,
          requestDataSchema,
        });

  const queryString = urlSearchParams ? `?${urlSearchParams}` : "";
  const fullUrl = `${getEndpointUrl(urlPath, environment)}${queryString}`;

  const response: Response = await doFetch({
    url: fullUrl,
    method,
    headers: isAuthenticated
      ? getHeaders({
          accessToken: await getAccessToken(),
          body: preparedRequestData,
        })
      : undefined,
    body: preparedRequestData,
  });

  if (!response.ok) {
    throw await getUnexpectedResponseError(response);
  }

  const formattedResponseData = await prepareResponseData({
    response,
    responseDataSchema,
  });

  return formattedResponseData;
}

export default makeRequest;
