import {
  useSavedScrollPosition,
  DashboardPrimaryTemplate,
} from "@taxbit-dashboard/commons";
import { Dropdown } from "@taxbit-private/cosmic";

import { ACCOUNTS_TABLE_VIEW_LABELS } from "./accountsLabelMaps";
import { AccountsTable } from "./table/AccountsTable";
import {
  ACCOUNTS_VIEW_OPTIONS,
  useAccountsUrlParams,
} from "./useAccountsUrlParams";

export enum AccountsTrackingId {
  AccountsViewDropdown = "accounts-view-dropdown",
}

const Accounts: React.FC = () => {
  const {
    urlParams: { view },
    setUrlParams,
  } = useAccountsUrlParams();

  useSavedScrollPosition({ key: "accounts" });

  return (
    <DashboardPrimaryTemplate
      title="Accounts"
      utilityElement={
        <Dropdown
          label="Accounts View"
          trackingId={AccountsTrackingId.AccountsViewDropdown}
          isLabelHidden={true}
          options={ACCOUNTS_VIEW_OPTIONS}
          value={view}
          getOptionKey={(value) => value}
          getOptionLabel={(value) => ACCOUNTS_TABLE_VIEW_LABELS[value]}
          onChange={(value) =>
            setUrlParams((draft) => {
              draft.view = value;
            })
          }
        />
      }
    >
      <AccountsTable />
    </DashboardPrimaryTemplate>
  );
};

export default Accounts;
