import { Environment, getEnvironment } from "./environment";
import { hostByEnvironment } from "./hosts";

export const getCurrentEndpointUrl = (path: string) => {
  return getEndpointUrl(path, getEnvironment());
};

const getEndpointUrl = (
  path: string,
  environment: Environment = Environment.Prod
) => {
  return `https://${hostByEnvironment[environment]}/v1.5/${path}`;
};

export default getEndpointUrl;
