import { useNavigate, useSearch } from "@tanstack/react-location";
import {
  cosmicPaginationLimitSchema,
  pageNumberSchema,
} from "@taxbit-dashboard/commons";
import { produce } from "immer";
import { useCallback, useMemo } from "react";
import { z } from "zod";

export const ACCOUNTS_VIEW_OPTIONS = ["ALL", "US", "NON_US"] as const;
const accountsViewSchema = z.enum(ACCOUNTS_VIEW_OPTIONS).catch("ALL");
export type AccountsView = z.infer<typeof accountsViewSchema>;

const accountsUrlParamsSchema = z.object({
  page: pageNumberSchema,
  limit: cosmicPaginationLimitSchema,
  view: accountsViewSchema,
});
export type AccountsUrlParams = z.infer<typeof accountsUrlParamsSchema>;

export const useAccountsUrlParams = () => {
  const rawUrlParams = useSearch();
  const urlParams = useMemo(
    () => accountsUrlParamsSchema.parse(rawUrlParams),
    [rawUrlParams]
  );

  const navigate = useNavigate();

  const setUrlParams = useCallback(
    (setter: (draft: AccountsUrlParams) => void) => {
      navigate({
        search: (params) => {
          const validatedParams = accountsUrlParamsSchema.parse(params ?? {});
          return produce(validatedParams, setter);
        },
      });
    },
    [navigate]
  );

  return { urlParams, setUrlParams };
};
