import { ReactNode } from "react";

import {
  NotificationsCenterDataContextProvider,
  useNotificationsCenterDataContext,
} from "./center/useNotificationsCenterData";
import {
  NotificationsPageDataContextProvider,
  useNotificationsPageDataContext,
} from "./page/useNotificationsPageData";

export enum NotificationsContext {
  Center = "NOTIFICATIONS_CENTER",
  Page = "NOTIFICATIONS_PAGE",
}

export const NotificationsContextProvider: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  return (
    <NotificationsCenterDataContextProvider>
      <NotificationsPageDataContextProvider>
        {children}
      </NotificationsPageDataContextProvider>
    </NotificationsCenterDataContextProvider>
  );
};

type PageContext = ReturnType<typeof useNotificationsPageDataContext>;
type CenterContext = ReturnType<typeof useNotificationsCenterDataContext>;

/**
 * This overload just tells TS that we will get the correct context based on the context argument.
 * It helps us avoid having to check for the existence of page/center specific context properties.
 */
function useNotificationsContext(
  context: NotificationsContext.Page
): PageContext;
function useNotificationsContext(
  context: NotificationsContext.Center
): CenterContext;
function useNotificationsContext(
  context: NotificationsContext
): PageContext & Partial<CenterContext>;
function useNotificationsContext(context: NotificationsContext) {
  const pageContext = useNotificationsPageDataContext();
  const centerContext = useNotificationsCenterDataContext();

  return context === NotificationsContext.Page ? pageContext : centerContext;
}

export default useNotificationsContext;
