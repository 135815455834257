import { createSingleInstanceHookContext } from "@taxbit-dashboard/commons";
import { useState, useMemo, useCallback, useRef } from "react";

import {
  NotificationsTab,
  notificationsTabTitleMap,
} from "../../../api/notifications/notificationsApiTypes";
import useNotifications from "../useNotifications";

const useNotificationsCenterData = () => {
  const [currentTab, setCurrentTab] = useState<NotificationsTab>(
    NotificationsTab.All
  );

  const params = useMemo(
    () =>
      ({
        tab: currentTab,
        limit: 25,
        page: 1,
      }) as const,
    [currentTab]
  );

  const { shouldShowUnreadNotifications, unreadCount, ...restContext } =
    useNotifications(params);

  const tabConfigs = useMemo(() => {
    return Object.values(NotificationsTab).map((tab) => {
      const shouldShowUnread =
        shouldShowUnreadNotifications && tab !== NotificationsTab.Read;

      const title = notificationsTabTitleMap[tab];

      return {
        label: shouldShowUnread ? `${title} (${unreadCount})` : title,
        onClick: () => setCurrentTab(tab),
        isActive: tab === currentTab,
        trackingId: tab,
      };
    });
  }, [currentTab, shouldShowUnreadNotifications, unreadCount]);

  const targetButtonRef = useRef<HTMLButtonElement>(null);

  // Simulates a button click to close the popover when navigating to the notifications page.
  const handleClose = useCallback(() => {
    if (targetButtonRef.current) {
      targetButtonRef.current.click();
    }
  }, []);

  return {
    currentTab,
    tabConfigs,
    params,
    shouldShowUnreadNotifications,
    targetButtonRef,
    handleClose,
    ...restContext,
  };
};

export const {
  Provider: NotificationsCenterDataContextProvider,
  useContextHook: useNotificationsCenterDataContext,
} = createSingleInstanceHookContext(
  useNotificationsCenterData,
  "useNotificationsCenterDataContext"
);
