import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CreatePayerMultipartFormInput,
  createPayerMultipartFormSchema,
  PayerId,
  UpdatePayerMultipartFormInput,
  updatePayerMultipartFormSchema,
} from "@taxbit-dashboard/rest";

import createQueryMetaObject from "./createQueryMetaObject";
import DashboardQueryKey from "./DashboardQueryKey";
import unwrapPublicApiWrappedQuery from "./unwrapPublicApiWrappedQuery";
import useTaxBitRest from "../useTaxBitRest";

export const useGetPayers = () => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.Payers],
    () => restSdk.payers.get(),
    {
      refetchInterval: 60 * 60 * 1000,
      ...createQueryMetaObject(restSdk.payers.buildPath()),
    }
  );
  return unwrapPublicApiWrappedQuery(query);
};

export const useCreatePayer = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation(
    (formInput: CreatePayerMultipartFormInput) => {
      const createPayerForm = createPayerMultipartFormSchema.parse({
        data: formInput.data,
        logo: formInput.logo,
      });
      return restSdk.payers.post(createPayerForm);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([DashboardQueryKey.Payers]);
      },
    }
  );
};

export const useUpdatePayer = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation(
    (params: {
      payerId: PayerId;
      formInput: UpdatePayerMultipartFormInput;
    }) => {
      const updatePayerForm = updatePayerMultipartFormSchema.parse(
        params.formInput
      );
      return restSdk.payer.patch({
        payerId: params.payerId,
        data: updatePayerForm,
      });
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([DashboardQueryKey.Payers]);
      },
    }
  );
};

export const useDeletePayer = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation((payerId: PayerId) => restSdk.payer.delete(payerId), {
    onSuccess: () => {
      void queryClient.invalidateQueries([DashboardQueryKey.Payers]);
    },
  });
};
