import {
  DashboardPrimaryTemplate,
  MissingDashboardConfigEmptyState,
} from "@taxbit-dashboard/commons";
import { FileAction } from "@taxbit-dashboard/rest";
import { Flex } from "@taxbit-private/cosmic";

import FilesTableCard from "./files-table-card/FilesTableCard";
import FileUploaderFormCard from "./FileUploaderFormCard";
import { useIngestFileUploaderContext } from "../context/ingest/IngestFileUploaderContext";

const IngestFileUploader = () => {
  const { hasIngestionTemplates } = useIngestFileUploaderContext();

  return (
    <DashboardPrimaryTemplate title="Ingest Data">
      {hasIngestionTemplates ? (
        <Flex direction="column" gap="l">
          <FileUploaderFormCard action={FileAction.Ingest} />
          <FilesTableCard action={FileAction.Ingest} />
        </Flex>
      ) : (
        <MissingDashboardConfigEmptyState />
      )}
    </DashboardPrimaryTemplate>
  );
};

export default IngestFileUploader;
