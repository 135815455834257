import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  Body,
  Checkbox,
  Divider,
  Flex,
  Modal,
  NumericalRowGroup,
} from "@taxbit-private/cosmic";
import pluralize from "pluralize";
import { useMemo, useState } from "react";

import useGenerateTaxFormsConfirmationModal, {
  GenerateTaxFormsConfirmationModalTrackingId,
} from "./useGenerateTaxFormsConfirmationModal";

type GenerateTaxFormsConfirmationModalProps = {
  shouldIgnoreSelectedItems?: boolean;
  onClose: () => void;
  isOpen: boolean;
};

const GenerateTaxFormsConfirmationModal = ({
  shouldIgnoreSelectedItems = false,
  onClose,
  isOpen,
}: GenerateTaxFormsConfirmationModalProps) => {
  const { hasDisableFormGenerationDataComparisonAccess } =
    useDashboardFeatureFlags();
  const [shouldIncludeFilters, setShouldIncludeFilters] = useState(false);
  const [shouldCompareData, setShouldCompareData] = useState(true);

  const {
    handleSubmit,
    totalEligibleAccounts,
    totalIneligibleAccounts,
    totalFilteredAccounts,
    isLoading,
    hasFiltersApplied,
  } = useGenerateTaxFormsConfirmationModal({
    shouldIgnoreSelectedItems,
    shouldCompareData,
    shouldIncludeFilters,
    onClose,
  });

  const totalRows = useMemo(
    () => [
      {
        label: "Total Eligible Accounts",
        value: totalEligibleAccounts,
        key: "totalEligible",
        variant: "bold",
      } as const,
      {
        label: "Total Ineligible Accounts",
        value: totalIneligibleAccounts,
        key: "totalIneligible",
        variant: "bold",
      } as const,
    ],
    [totalIneligibleAccounts, totalEligibleAccounts]
  );

  const shouldShowCheckboxes =
    hasDisableFormGenerationDataComparisonAccess ||
    (shouldIgnoreSelectedItems && hasFiltersApplied);

  return (
    <Modal
      title="Generate Forms"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={
        GenerateTaxFormsConfirmationModalTrackingId.CloseButton
      }
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: GenerateTaxFormsConfirmationModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Generate Forms",
        trackingId: GenerateTaxFormsConfirmationModalTrackingId.SubmitButton,
        isLoading,
        loadingText: "Processing",
        variant: "button-primary",
        onClick: handleSubmit,
      }}
    >
      <Flex direction="column" gap="m">
        <Body>
          By clicking &quot;Generate Forms&quot; below, a form will be generated
          for all selected eligible accounts with complete account data, as well
          as any ineligible accounts that require a Void form.
        </Body>
        <Body>
          A form will not be generated for the following:
          <ul>
            <li>
              Eligible accounts with an existing form, for which data has not
              changed
            </li>
            <li>Eligible accounts with incomplete account data</li>
            <li>Eligible accounts that have been removed from scope</li>
            <li>
              Ineligible accounts that do not have a previously filed form
            </li>
          </ul>
        </Body>
        {shouldShowCheckboxes && (
          <>
            <Divider />
            {shouldIgnoreSelectedItems && hasFiltersApplied && (
              <Checkbox
                isChecked={shouldIncludeFilters}
                label={`Generate forms for filtered population only (${totalFilteredAccounts} ${pluralize(
                  "account",
                  totalFilteredAccounts
                )})`}
                onChange={() => setShouldIncludeFilters((prev) => !prev)}
                trackingId={
                  GenerateTaxFormsConfirmationModalTrackingId.FilteredPopulationCheckbox
                }
              />
            )}
            {hasDisableFormGenerationDataComparisonAccess && (
              <Checkbox
                isChecked={!shouldCompareData}
                label="Generate forms without data comparison"
                onChange={() => setShouldCompareData((prev) => !prev)}
                trackingId={
                  GenerateTaxFormsConfirmationModalTrackingId.DataComparisonCheckbox
                }
              />
            )}
          </>
        )}
        <Divider />
        <NumericalRowGroup rows={totalRows} />
      </Flex>
    </Modal>
  );
};

export default GenerateTaxFormsConfirmationModal;
