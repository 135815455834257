import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import {
  DashboardAppRoutePath,
  serializeScopeClaim,
  UserPermission,
  vanillaStore,
} from "@taxbit-dashboard/commons";
import { isProdEnvironment } from "@taxbit-dashboard/rest";

/**
 * This is a scope string passed to Auth0 when retrieving an access token. It contains
 * all scopes (user permissions) for all API requests the app would like to make on
 * behalf of the user. The scope string contained within the returned access token
 * represents the subset of these requested permissions that the user has been assigned.
 * https://auth0.com/docs/get-started/apis/scopes
 */
const scope = serializeScopeClaim(Object.values(UserPermission));

const options: Auth0ProviderOptions = {
  domain: isProdEnvironment()
    ? "auth-enterprise.taxbit.com"
    : "auth-enterprise-staging.taxbit.com",
  clientId: isProdEnvironment()
    ? "h8OqsAjsGiNYFWHTJZpvki7NgsnP3K3u"
    : "JJI49MKogCrHHc38TUpZcRD6NAYlBvoB",
  /**
   * When redirecting, store the `returnTo` path in global state so
   * that it can be accessed by the Callback component found at our redirect path.
   */
  onRedirectCallback: (appState) => {
    vanillaStore.setState({
      returnTo: appState?.returnTo ?? "/",
    });
  },
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  authorizationParams: {
    redirect_uri: `${window.location.origin}${DashboardAppRoutePath.Callback}`,
    scope,
  },
};

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Auth0Provider {...options}>{children}</Auth0Provider>;
};

export default AuthProvider;
