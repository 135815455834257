import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";

import Accounts from "./Accounts";
import NewAccounts from "../new-accounts/Accounts";

const AccountsRouter: React.FC = () => {
  const { hasNewAccountsPageAccess } = useDashboardFeatureFlags();
  if (hasNewAccountsPageAccess) {
    return <NewAccounts />;
  }
  return <Accounts />;
};

export default AccountsRouter;
