import {
  Popover,
  PopoverProps,
  TopNavigationActionButton,
  withTracking,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

type TopNavigationPopoverProps = PopoverProps<typeof TopNavigationActionButton>;

type Props = {
  contents: TopNavigationPopoverProps["contents"];
  hasDot: boolean;
  targetButtonProps: Omit<TopNavigationPopoverProps["targetButtonProps"], "as">;
  targetButtonRef?: TopNavigationPopoverProps["targetButtonRef"];
};

export enum TopNavigationPopoverTrackingId {
  Dot = "top-navigation-popover-dot",
}

const TopNavigationPopover: React.FC<Props> = ({
  contents,
  hasDot,
  targetButtonProps,
  targetButtonRef,
}) => {
  return (
    <Wrapper>
      <Popover
        targetButtonProps={{
          as: TopNavigationActionButton,
          ...targetButtonProps,
        }}
        targetButtonRef={targetButtonRef}
        contents={contents}
      />
      {hasDot && <Dot trackingId={TopNavigationPopoverTrackingId.Dot} />}
    </Wrapper>
  );
};

export default TopNavigationPopover;

const Wrapper = styled.div`
  position: relative;
`;

const Dot = withTracking(
  styled.div(
    ({ theme }) => `
  width: ${theme.sizing.xxxs};
  height: ${theme.sizing.xxxs};
  background-color: ${theme.color.red0};
  border-radius: ${theme.borderRadius.round};
  position: absolute;
  top: 1px;
  right: 1px;
`
  )
);
