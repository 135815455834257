import { pageLimitPaginationParamsSchema } from "@taxbit-dashboard/commons";
import { NotificationCategory } from "@taxbit-dashboard/rest";
import { AvatarProps, IconName } from "@taxbit-private/cosmic";
import { z } from "zod";

export const notificationCategoryToUiConfigMap: Record<
  NotificationCategory,
  {
    label: string;
    avatarVariant: AvatarProps["variant"];
    icon: IconName;
  }
> = {
  [NotificationCategory.IngestionFailed]: {
    label: "Ingestion failed",
    avatarVariant: "danger",
    icon: "alert-circle",
  },
  [NotificationCategory.InvalidFile]: {
    label: "Ingestion failed - invalid file",
    avatarVariant: "danger",
    icon: "alert-circle",
  },
  [NotificationCategory.AccountIngestionValidationComplete]: {
    label: "Account ingestion validation complete",
    avatarVariant: "success",
    icon: "check-circle",
  },
  [NotificationCategory.TransactionIngestionValidationComplete]: {
    label: "Transaction ingestion validation complete",
    avatarVariant: "success",
    icon: "check-circle",
  },
  [NotificationCategory.FormIngestionValidationComplete]: {
    label: "Form data ingestion validation complete",
    avatarVariant: "success",
    icon: "check-circle",
  },
  [NotificationCategory.AccountIngestionComplete]: {
    label: "Account ingestion complete",
    avatarVariant: "success",
    icon: "upload",
  },
  [NotificationCategory.TransactionIngestionComplete]: {
    label: "Transaction ingestion complete",
    avatarVariant: "success",
    icon: "upload",
  },
  [NotificationCategory.FormIngestionComplete]: {
    label: "Form data ingestion complete",
    avatarVariant: "success",
    icon: "upload",
  },
  [NotificationCategory.AccountDeletionValidationComplete]: {
    label: "Account deletion validation",
    avatarVariant: "success",
    icon: "check-circle",
  },
  [NotificationCategory.FormDeletionValidationComplete]: {
    label: "Form data deletion validation",
    avatarVariant: "success",
    icon: "check-circle",
  },
  [NotificationCategory.TransactionDeletionValidationComplete]: {
    label: "Transaction deletion validation",
    avatarVariant: "success",
    icon: "check-circle",
  },
  [NotificationCategory.AccountDeletionComplete]: {
    label: "Account deletion complete",
    avatarVariant: "success",
    icon: "trash",
  },
  [NotificationCategory.FormDeletionComplete]: {
    label: "Form data deletion complete",
    avatarVariant: "success",
    icon: "trash",
  },
  [NotificationCategory.TransactionDeletionComplete]: {
    label: "Transaction deletion complete",
    avatarVariant: "success",
    icon: "trash",
  },
  [NotificationCategory.AccountsExportComplete]: {
    label: "Account table export generation completed",
    avatarVariant: "success",
    icon: "file",
  },
  [NotificationCategory.EligibilityExportComplete]: {
    label: "Eligibility table export generation complete",
    avatarVariant: "success",
    icon: "file",
  },
  [NotificationCategory.FormsExportComplete]: {
    label: "Forms table export generation completed",
    avatarVariant: "success",
    icon: "file",
  },
  [NotificationCategory.ReportGenerationComplete]: {
    label: "Report generation completed",
    avatarVariant: "success",
    icon: "file",
  },
  [NotificationCategory.QaPackageGenerationComplete]: {
    label: "QA package generation completed",
    avatarVariant: "success",
    icon: "package",
  },
  [NotificationCategory.FormGenerationComplete]: {
    label: "Form generation complete",
    avatarVariant: "success",
    icon: "check-circle",
  },
  [NotificationCategory.FormDownloadComplete]: {
    label: "Form download package completed",
    avatarVariant: "success",
    icon: "download",
  },
};

export enum NotificationsTab {
  All = "all",
  Unread = "unread",
  Read = "read",
}

export const notificationsTabTitleMap = {
  [NotificationsTab.All]: "All",
  [NotificationsTab.Read]: "Read",
  [NotificationsTab.Unread]: "Unread",
};

export const notificationsPageParamsSchema =
  pageLimitPaginationParamsSchema.extend({
    tab: z.nativeEnum(NotificationsTab).catch(NotificationsTab.All),
  });

export type NotificationsPageParams = z.infer<
  typeof notificationsPageParamsSchema
>;
