import { Outlet } from "@tanstack/react-location";
import { Flex, getTopNavigationHeight } from "@taxbit-private/cosmic";
import styled from "styled-components";

import { AppWrapper } from "./AppWrapper";
import useScrollPagesToTop from "./hooks/useScrollPagesToTop";
import LeftNavigation, { NavigationSidebarSection } from "./LeftNavigation";
import AppNotifications from "../notifications/AppNotifications";
import usePageScroll from "../utils/usePageScroll";

type Props = {
  sections: readonly NavigationSidebarSection[];
};

const AppOutlet: React.FC<Props> = ({ sections }) => {
  const { pageScrollElementRef } = usePageScroll();
  useScrollPagesToTop();

  return (
    <AppWrapper>
      <LeftNavigation sections={sections} />
      <Flex direction="column">
        <AppNotifications />
        <PageWrapper ref={pageScrollElementRef}>
          <Outlet />
        </PageWrapper>
      </Flex>
    </AppWrapper>
  );
};

export default AppOutlet;

const PageWrapper = styled.div(
  ({ theme }) => `
  height: calc(100vh - ${getTopNavigationHeight(theme)});
  overflow: auto;
`
);
