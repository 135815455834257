import {
  InlineSpinner,
  Popover,
  TopNavigationOrganizationPickerToggleButton,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

import TopNavigationActionMenuContents from "./TopNavigationActionMenuContents";
import { useTopNavigationActionMenuContext } from "./useTopNavigationActionMenu";
import getInitialsForTopNavDropdown from "../shared/getInitialsForTopNavDropdown";

export enum TopNavigationActionMenuTrackingId {
  TargetButton = "top-navigation-action-menu-target-button",
}

const TopNavigationActionMenu = () => {
  const {
    isLoading,
    actionMenuTargetButtonRef,
    onCloseActionMenu,
    hasMoreThanOneOrganizationForCurrentCompany,
    currentOrganization,
    isSwitchingOrg,
    currentCompanyUser,
  } = useTopNavigationActionMenuContext();

  if (isLoading) {
    return <WhiteSpinner />;
  }

  return (
    <Popover
      targetButtonProps={{
        as: TopNavigationOrganizationPickerToggleButton,
        trackingId: TopNavigationActionMenuTrackingId.TargetButton,
        companyName: hasMoreThanOneOrganizationForCurrentCompany
          ? currentOrganization?.companyName
          : undefined,
        organizationName: currentOrganization?.name,
        disabled: isSwitchingOrg,
        initials: getInitialsForTopNavDropdown(currentCompanyUser.name),
      }}
      targetButtonRef={actionMenuTargetButtonRef}
      contents={<TopNavigationActionMenuContents />}
      onMenuToggle={onCloseActionMenu}
    />
  );
};

const WhiteSpinner = styled(InlineSpinner)(
  ({ theme }) => `
    color: ${theme.color.white}`
);

export default TopNavigationActionMenu;
