import {
  UrlFilterParams,
  createSingleInstanceHookContext,
  useUrlFilterParams,
} from "@taxbit-dashboard/commons";
import { useMemo, useCallback } from "react";

import validateNotificationsPageParams from "./validateNotificationsPageParams";
import {
  NotificationsPageParams,
  NotificationsTab,
} from "../../../api/notifications/notificationsApiTypes";
import useNotifications, {
  notificationsTabTitleMap,
} from "../useNotifications";

const useNotificationsPageData = () => {
  const validateParams = useCallback(
    (rawUrlParams: UrlFilterParams<NotificationsPageParams>) =>
      validateNotificationsPageParams(rawUrlParams),
    []
  );

  const {
    urlParams,
    setUrlParams,
    setUrlParamsAndResetPagination: setFilterParams,
  } = useUrlFilterParams({
    validateParams,
  });

  const { shouldShowUnreadNotifications, unreadCount, ...restContext } =
    useNotifications(urlParams);

  const setCurrentTab = useCallback(
    (tab: NotificationsTab) => {
      setFilterParams((draft) => {
        draft.tab = tab;
      });
    },
    [setFilterParams]
  );

  const tabConfigs = useMemo(() => {
    return Object.values(NotificationsTab).map((tab) => {
      const shouldShowUnread =
        shouldShowUnreadNotifications && tab !== NotificationsTab.Read;

      const title = notificationsTabTitleMap[tab];

      return {
        label: shouldShowUnread ? `${title} (${unreadCount})` : title,
        onClick: () => setCurrentTab(tab),
        isActive: tab === urlParams.tab,
        trackingId: tab,
      };
    });
  }, [
    setCurrentTab,
    shouldShowUnreadNotifications,
    unreadCount,
    urlParams.tab,
  ]);

  return {
    currentTab: urlParams.tab,
    tabConfigs,
    params: urlParams,
    setUrlParams,
    shouldShowUnreadNotifications,
    ...restContext,
  };
};

export const {
  Provider: NotificationsPageDataContextProvider,
  useContextHook: useNotificationsPageDataContext,
} = createSingleInstanceHookContext(
  useNotificationsPageData,
  "useNotificationsPageDataContext"
);
