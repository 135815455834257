enum DashboardQueryKey {
  Accounts = "Accounts",
  AccountsExport = "AccountsExport",
  AccountsV2 = "AccountsV2",
  AccountOwnerCurings = "AccountOwnerCurings",
  AccountOwnerCuringArchives = "AccountOwnerCuringArchives",
  AccountOwnerDetails = "AccountOwnerDetails",
  AccountTin = "AccountTin",
  Assets = "Assets",
  CompanyUsers = "CompanyUsers",
  CurrentCompanyUser = "CurrentCompanyUser",
  CurrentCompanyUserOrganizations = "CurrentCompanyUserOrganizations",
  CurrentCompanyUserDownloads = "CurrentCompanyUserDownloads",
  EligibilityAlerts = "EligibilityAlerts",
  EligibilityCounts = "EligibilityCounts",
  EligibilityData = "EligibilityData",
  EligibilityExport = "EligibilityExport",
  EligibilityOverride = "EligibilityOverride",
  EligibilityRecalc = "EligibilityRecalc",
  EligibilitySyncTimestamps = "EligibilitySyncTimestamps",
  FileErrorReport = "FileErrorReport",
  Files = "Files",
  FileUrl = "FileUrl",
  FormItems = "FormItems",
  FormMetadata = "FormMetadata",
  Gains = "Gains",
  GainsSummary = "GainsSummary",
  InventorySummary = "InventorySummary",
  Invitations = "Invitations",
  HydratedAccounts = "HydratedAccounts",
  IrForms = "IrForms",
  IrFormsAggregates = "IrFormsAggregates",
  IrFormsBatch = "IrFormsBatch",
  IrFormsExport = "IrFormsExport",
  IrFormsRescind = "IrFormsRescind",
  KycTaxDocumentation = "KycTaxDocumentation",
  KycTaxDocumentationDocument = "KycTaxDocumentationDocument",
  KycTaxDocumentationStatus = "KycTaxDocumentationStatus",
  KycTaxDocumentationSummary = "KycTaxDocumentationSummary",
  LegacyAccounts = "LegacyAccounts",
  Notifications = "Notifications",
  Notification = "Notification",
  OrganizationMembers = "OrganizationMembers",
  OrganizationsByCompanyUserEmail = "OrganizationsByCompanyUserEmail",
  Payers = "Payers",
  Roles = "Roles",
  TaxDocumentation = "TaxDocumentation",
  TaxDocumentationStatus = "TaxDocumentationStatus",
  TaxForms = "TaxForms",
  TaxReports = "TaxReports",
  TaxReportStatus = "TaxReportStatus",
  TaxReportUrl = "TaxReportUrl",
  Transactions = "Transactions",
  TransactionsCsv = "TransactionsCsv",
  TransferOutLots = "TransferOutLots",
  TransfersIn = "TransfersIn",
  TransfersInMetadata = "TransfersInMetadata",
  TransfersOut = "TransfersOut",
  TransfersOutMetadata = "TransfersOutMetadata",
  UmcReports = "UmcReports",
  UmcReportUrl = "UmcReportUrl",
}

export default DashboardQueryKey;
