import {
  NAVIGATION_SIDEBAR_WIDTH,
  getTopNavigationHeight,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

/**
 * The basic layout wrapper for the authenticated app. This lives in its
 * own component file to avoid a dependency cycle.
 */
export const AppWrapper = styled.div(
  ({ theme }) => `
  position: fixed;
  top: ${getTopNavigationHeight(theme)};
  width: 100%;
  display: grid;
  grid-template-columns: ${NAVIGATION_SIDEBAR_WIDTH} 1fr;
`
);
