import { useSetBackHrefAndNavigate } from "@taxbit-dashboard/commons";
import { HydratedAccount } from "@taxbit-dashboard/rest/";
import {
  getEnUsErrorEmptyStateProps,
  useCosmicTable,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";
import { useMemo, useCallback } from "react";

import { useAccountsTableColumns } from "./useAccountsTableColumns";
import { useGetHydratedAccounts } from "../../../../api/hydrated-accounts/hydratedAccountsApi";
import { useAccountsUrlParams } from "../useAccountsUrlParams";

export const useAccountsTable = () => {
  const {
    urlParams: { page, limit },
  } = useAccountsUrlParams();
  const columns = useAccountsTableColumns();

  const { data, isLoading, isError, meta } = useGetHydratedAccounts({
    page,
    limit,
  });

  const memoizedGetRowKey = useCallback(
    (row: HydratedAccount) => row.externalId,
    []
  );

  const rows = useMemo(() => data ?? [], [data]);

  const searchEmptyStateProps = useSearchEmptyStateProps();

  const setBackHrefAndNavigate = useSetBackHrefAndNavigate();

  const tableProps = useCosmicTable({
    isManualSortable: false,
    getRowKey: memoizedGetRowKey,
    shouldShowAutomaticPlaceholders: true,
    density: "compact",
    rows,
    columns,
    isLoading,
    emptyStateProps: isError
      ? getEnUsErrorEmptyStateProps({ entity: "accounts" })
      : searchEmptyStateProps,
    getRowOnClick: ({ id }) => {
      setBackHrefAndNavigate({
        to: `./${id}`,
        backHrefKey: "account-details",
        reset: ["transactions", "transaction-details"],
      });
    },
  });

  const shouldDisableControls = isLoading || isError;

  return {
    tableProps,
    shouldDisableControls,
    totalCount: meta?.page?.totalCount ?? 0,
  };
};
