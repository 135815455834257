import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import { OffsetPaginationApiParams } from "./sharedApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export enum NotificationCategory {
  InvalidFile = "INVALID_FILE",
  AccountDeletionComplete = "ACCOUNT_DELETION_COMPLETE",
  AccountDeletionValidationComplete = "ACCOUNT_DELETION_VALIDATION_COMPLETE",
  AccountIngestionComplete = "ACCOUNT_INGESTION_COMPLETE",
  AccountIngestionValidationComplete = "ACCOUNT_INGESTION_VALIDATION_COMPLETE",
  AccountsExportComplete = "ACCOUNTS_EXPORT_COMPLETE",
  EligibilityExportComplete = "ELIGIBILITY_EXPORT_COMPLETE",
  FormDeletionComplete = "FORM_DELETION_COMPLETE",
  FormDeletionValidationComplete = "FORM_DELETION_VALIDATION_COMPLETE",
  FormDownloadComplete = "FORM_DOWNLOAD_COMPLETE",
  FormGenerationComplete = "FORM_GENERATION_COMPLETE",
  FormIngestionComplete = "FORM_INGESTION_COMPLETE",
  FormIngestionValidationComplete = "FORM_INGESTION_VALIDATION_COMPLETE",
  FormsExportComplete = "FORMS_EXPORT_COMPLETE",
  IngestionFailed = "INGESTION_FAILED",
  QaPackageGenerationComplete = "QA_PACKAGE_GENERATION_COMPLETE",
  ReportGenerationComplete = "REPORT_GENERATION_COMPLETE",
  TransactionDeletionComplete = "TRANSACTION_DELETION_COMPLETE",
  TransactionDeletionValidationComplete = "TRANSACTION_DELETION_VALIDATION_COMPLETE",
  TransactionIngestionComplete = "TRANSACTION_INGESTION_COMPLETE",
  TransactionIngestionValidationComplete = "TRANSACTION_INGESTION_VALIDATION_COMPLETE",
}

export enum NotificationActionType {
  Href = "HREF",
  Download = "DOWNLOAD",
  Support = "SUPPORT",
}

const notificationSchema = z.object({
  notificationId: uuidSchema,
  organizationId: z.string(),
  category: z.nativeEnum(NotificationCategory),
  occurredAt: z.string(),
  description: z.string(),
  isRead: z.boolean(),
  recipients: z.array(z.string()).optional(),
  actions: z
    .object({
      actionUrl: z.string(),
      actionText: z.string(),
      type: z.nativeEnum(NotificationActionType),
    })
    .array()
    .optional(),
});

export type Notification = z.infer<typeof notificationSchema>;

export const getNotificationsResponseSchema = getPublicApiSuccessSchema(
  z.array(notificationSchema)
);

export type GetNotificationsResponse = z.infer<
  typeof getNotificationsResponseSchema
>;

export type GetNotificationsParams = {
  "filters[is_read]"?: boolean;
} & OffsetPaginationApiParams;

export const getNotificationResponseSchema =
  getPublicApiSuccessSchema(notificationSchema);

export const markNotificationAsReadRequestSchema = z.array(uuidSchema);
