import NotificationsCenterContents from "./NotificationsCenterContents";
import TopNavigationPopover from "../../app/navigation/top-navigation/shared/TopNavigationPopover";
import useNotificationsContext, {
  NotificationsContext,
} from "../useNotificationsContext";

export enum NotificationsCenterTrackingId {
  ToggleButton = "notifications-center-toggle-button",
}

const NotificationsCenter: React.FC = () => {
  const { shouldShowUnreadNotifications, isLoading, targetButtonRef } =
    useNotificationsContext(NotificationsContext.Center);

  return (
    <TopNavigationPopover
      targetButtonProps={{
        iconName: "bell",
        trackingId: NotificationsCenterTrackingId.ToggleButton,
        label: "Notifications",
        isDisabled: isLoading,
      }}
      targetButtonRef={targetButtonRef}
      contents={<NotificationsCenterContents />}
      hasDot={shouldShowUnreadNotifications}
    />
  );
};

export default NotificationsCenter;
