import { calculatePageNumberForUpdatedPageSize } from "@taxbit-dashboard/commons";
import { CosmicPageSize, PaginationFooter } from "@taxbit-private/cosmic";
import { useCallback } from "react";

import { useAccountsUrlParams } from "../useAccountsUrlParams";

export enum AccountsTablePaginationTrackingId {
  Pagination = "accounts-table-pagination",
}

type AccountsTablePaginationProps = {
  isDisabled: boolean;
  totalCount: number;
};

const AccountsTablePagination: React.FC<AccountsTablePaginationProps> = ({
  isDisabled,
  totalCount,
}) => {
  const {
    urlParams: { page, limit },
    setUrlParams,
  } = useAccountsUrlParams();

  const setPage = useCallback(
    (nextPage: number) => {
      setUrlParams((draft) => {
        draft.page = nextPage;
      });
    },
    [setUrlParams]
  );

  const setLimit = useCallback(
    (nextLimit: CosmicPageSize) => {
      const newPage = calculatePageNumberForUpdatedPageSize({
        currentPageSize: limit,
        currentPage: page,
        nextPageSize: nextLimit,
      });

      setUrlParams((draft) => {
        draft.page = newPage;
        draft.limit = nextLimit;
      });
    },
    [limit, page, setUrlParams]
  );

  return (
    <PaginationFooter
      currentPage={page}
      isDisabled={isDisabled}
      pageSize={limit}
      setCurrentPage={setPage}
      setPageSize={setLimit}
      totalCount={totalCount}
      trackingId={AccountsTablePaginationTrackingId.Pagination}
    />
  );
};

export default AccountsTablePagination;
