import { Modal, ModalProps } from "@taxbit-private/cosmic";

export enum FilerSetupTableDeleteModalTrackingId {
  CloseButton = "filer-setup-table-delete-modal-close-button",
  ConfirmButton = "filer-setup-table-delete-modal-confirm-button",
  CancelButton = "filer-setup-table-delete-modal-cancel-button",
}

type Props = Pick<ModalProps, "isOpen" | "onClose"> & {
  onConfirm: () => void;
  payerName: string;
  isLoading: boolean;
};

const FilerSetupTableDeleteModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  payerName,
  isLoading,
}) => {
  return (
    <Modal
      title="Filer Delete Confirmation"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={FilerSetupTableDeleteModalTrackingId.CloseButton}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: FilerSetupTableDeleteModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Delete",
        trackingId: FilerSetupTableDeleteModalTrackingId.ConfirmButton,
        type: "submit",
        variant: "button-danger",
        onClick: onConfirm,
        isDisabled: isLoading,
      }}
    >
      {`Please confirm you'd like to delete ${payerName}.`}
    </Modal>
  );
};

export default FilerSetupTableDeleteModal;
