import { z } from "zod";

export enum Environment {
  Prod = "prod",
  Staging = "staging",
  Dev = "dev",
}

export const environmentSchema = z.nativeEnum(Environment);

export const isProdEnvironment = (): boolean => {
  return getEnvironment() === Environment.Prod;
};

export const isStagingEnvironment = (): boolean => {
  return [Environment.Staging, Environment.Dev].includes(getEnvironment());
};

export const isDevEnvironment = (): boolean => {
  return getEnvironment() === Environment.Dev;
};

export const getEnvironment = (): Environment => {
  switch (window.location.host) {
    case "dashboard.taxbit.com": {
      return Environment.Prod;
    }
    case "staging.dashboard.taxbit.com": {
      return Environment.Staging;
    }
    default: {
      return Environment.Dev;
    }
  }
};
