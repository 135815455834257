import { DispositionMethod } from "./payersRestApiTypes";

export const payerDispositionMethodLabelMap: Record<DispositionMethod, string> =
  {
    [DispositionMethod.EnterpriseExchangeHifo]: "Highest In, First Out (HIFO)",
    [DispositionMethod.EnterpriseExchangeFifo]: "First In, First Out (FIFO)",
    [DispositionMethod.EnterpriseExchangeLifo]: "Last In, First Out (LIFO)",
    [DispositionMethod.UniversalFifo]: "Universal FIFO",
    [DispositionMethod.UniversalHifo]: "Universal HIFO",
    [DispositionMethod.ExchangeHifo]: "Exchange HIFO",
    [DispositionMethod.GbIndividual]: "GB Individual",
    [DispositionMethod.GbBusiness]: "GB Business",
    [DispositionMethod.LuWac]: "LU WAC",
  };
