import {
  ToastConfig,
  hasDefinedValues,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import { EligibilityStatus } from "@taxbit-dashboard/rest";

import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export enum GenerateTaxFormsConfirmationModalTrackingId {
  FilteredPopulationCheckbox = "ir-eligibility-generate-tax-forms-confirmation-modal-filtered-population-checkbox",
  DataComparisonCheckbox = "ir-eligibility-generate-tax-forms-confirmation-modal-data-comparison-checkbox",
  CloseButton = "ir-eligibility-generate-tax-forms-confirmation-modal-close-btn",
  CancelButton = "ir-eligibility-generate-tax-forms-confirmation-modal-cancel-btn",
  SubmitButton = "ir-eligibility-generate-tax-forms-confirmation-modal-submit-btn",
  SuccessToast = "ir-eligibility-generate-tax-forms-confirmation-modal-success-toast",
  ErrorToast = "ir-eligibility-generate-tax-forms-confirmation-modal-error-toast",
}

export const generateTaxFormsSuccessToastContent: ToastConfig = {
  message: "The tax forms are generating.",
  trackingId: GenerateTaxFormsConfirmationModalTrackingId.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
};

export const generateTaxFormsErrorToastContent: ToastConfig = {
  message: "The tax forms failed to generate. Please try again.",
  variant: "danger",
  trackingId: GenerateTaxFormsConfirmationModalTrackingId.ErrorToast,
  timeoutMs: TOAST_TIMEOUT,
};

const useGenerateTaxFormsConfirmationModal = ({
  shouldIgnoreSelectedItems = false,
  shouldIncludeFilters = false,
  shouldCompareData,
  onClose,
}: {
  shouldIgnoreSelectedItems?: boolean;
  shouldIncludeFilters?: boolean;
  shouldCompareData: boolean;
  onClose: () => void;
}) => {
  const {
    urlParams: { formDate, formType, page, limit, ...filterParams },
    selectedItems,
    eligibilityCounts,
    totalCount,
    shouldDisableControls,
    generateTaxForms,
    toggleAllSelectedItems,
  } = useEligibilityDataContext();
  const addToast = useDashboardStore((state) => state.addToast);

  const { completeAccountData = 0, incompleteAccountData = 0 } =
    eligibilityCounts?.eligible || {};
  const totalEligibleAccounts = shouldIgnoreSelectedItems
    ? completeAccountData + incompleteAccountData
    : selectedItems.filter(
        (account) =>
          account.status === EligibilityStatus.Eligible ||
          account.status === EligibilityStatus.Added
      ).length;

  const {
    exempt = 0,
    nonValidTaxCountryCode = 0,
    noReportableTransactions = 0,
    removed = 0,
  } = eligibilityCounts?.ineligible || {};

  const totalIneligibleAccounts = shouldIgnoreSelectedItems
    ? exempt + nonValidTaxCountryCode + noReportableTransactions + removed
    : selectedItems.filter(
        (account) =>
          account.status !== EligibilityStatus.Eligible &&
          account.status !== EligibilityStatus.Added
      ).length;

  const handleSubmit = () => {
    generateTaxForms(
      {
        shouldIncludeFilters,
        shouldCompareData,
        items: shouldIgnoreSelectedItems ? undefined : selectedItems,
      },
      {
        onSuccess: () => {
          addToast(generateTaxFormsSuccessToastContent);
        },
        onError: () => {
          addToast(generateTaxFormsErrorToastContent);
        },
      }
    );

    onClose();
    toggleAllSelectedItems(false);
  };

  return {
    handleSubmit,
    totalEligibleAccounts,
    totalIneligibleAccounts,
    totalFilteredAccounts: totalCount,
    isLoading: shouldDisableControls,
    hasFiltersApplied: hasDefinedValues(filterParams),
  };
};

export default useGenerateTaxFormsConfirmationModal;
