import { Environment, getEnvironment } from "./environment";

export const hostByEnvironment: Record<Environment, string> = {
  [Environment.Prod]: "api-ent.taxbit.com",
  [Environment.Staging]: "staging.api-ent.taxbit.com",
  [Environment.Dev]: "staging.api-ent.taxbit.com",
};

export const getCurrentHost = () => hostByEnvironment[getEnvironment()];

export const websocketHostByEnvironment: Record<Environment, string> = {
  [Environment.Prod]: "ws.multi1.enterprise.taxbit.com",
  [Environment.Staging]: "ws.multi1.enterprise-staging.taxbit.com",
  [Environment.Dev]: "ws.multi1.enterprise-staging.taxbit.com",
};

export const getCurrentWebsocketHost = () =>
  websocketHostByEnvironment[getEnvironment()];
