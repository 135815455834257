import { useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import { GetHydratedAccountsParams } from "@taxbit-dashboard/rest";

export const useGetHydratedAccounts = (params: GetHydratedAccountsParams) => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.HydratedAccounts, { ...params }],
    () => restSdk.hydratedAccounts.get(params),
    {
      ...createQueryMetaObject(restSdk.hydratedAccounts.buildPath()),
      keepPreviousData: true,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
