import { useRouter } from "@tanstack/react-location";
import { dashboardFormTypeSchema } from "@taxbit-dashboard/rest";
import { fourDigitYearSchema } from "@taxbit-private/datetime";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import z from "zod";

import useIsSubrouteMatch from "./useIsSubrouteMatch";
import useUrlFilterParams from "./useUrlFilterParams";
import createSingleInstanceHookContext from "../../utils/createSingleInstanceHookContext";

const ELIGIBLE_ROUTES = ["/ir/eligibility", "/ir/forms", "/ir/summary"];

const optionalFormYearDropdownParamsSchema = z.object({
  formType: dashboardFormTypeSchema.optional().catch(undefined),
  formDate: fourDigitYearSchema.optional().catch(undefined),
});

type OptionalFormYearDropdownParams = z.infer<
  typeof optionalFormYearDropdownParamsSchema
>;

const useSharedFormYearDropdownParams = () => {
  const { urlParams, setUrlParams } =
    useUrlFilterParams<OptionalFormYearDropdownParams>({
      validateParams: (params) =>
        optionalFormYearDropdownParamsSchema.parse(params),
    });

  const [lastParams, setLastParams] =
    useState<OptionalFormYearDropdownParams>(urlParams);

  const { pending, state } = useRouter();
  const isSubrouteMatch = useIsSubrouteMatch();

  const currentPath = state.location.pathname;
  const pendingPath = pending?.location.pathname;

  const isPendingRouteEligible = ELIGIBLE_ROUTES.some((route) =>
    isSubrouteMatch(route, true)
  );

  const haveParamsChanged = useMemo(
    () => !_.isEqual(lastParams, urlParams),
    [lastParams, urlParams]
  );

  useEffect(() => {
    const shouldSaveParams = !!(
      (urlParams.formType || urlParams.formDate) &&
      haveParamsChanged
    );

    if (shouldSaveParams) {
      setLastParams(urlParams);
    }
  }, [
    haveParamsChanged,
    lastParams,
    lastParams.formDate,
    lastParams.formType,
    urlParams,
  ]);

  useEffect(() => {
    const isNavigatingToNewPage = pendingPath !== currentPath;

    /**
     * Handles the edge case where a user clicks the current page's navigation item. This
     * has the effect of wiping the URL params, so we need to reapply them.
     */
    const isFreshLoadOfSamePage =
      pendingPath === currentPath && _.isEmpty(urlParams);

    const shouldApplySavedParams = (() => {
      if (haveParamsChanged && isPendingRouteEligible) {
        return isNavigatingToNewPage || isFreshLoadOfSamePage;
      } else {
        return false;
      }
    })();

    if (shouldApplySavedParams) {
      setUrlParams((draft) => {
        draft.formType = lastParams.formType;
        draft.formDate = lastParams.formDate;
      });
    }
  }, [
    currentPath,
    haveParamsChanged,
    isPendingRouteEligible,
    lastParams,
    pendingPath,
    setUrlParams,
    urlParams,
  ]);
};

export const { Provider: SharedFormYearDropdownParamsContextProvider } =
  createSingleInstanceHookContext(
    useSharedFormYearDropdownParams,
    "useSharedFormYearDropdownParams"
  );
