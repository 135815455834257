import {
  AccountsTemplateType,
  FileType,
  TransactionsTemplateType,
  dashboardFormTypeSchema,
} from "@taxbit-dashboard/rest";
import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { z } from "zod";

import { getTemplateTypeForFile } from "../../../api/files/filesApiUtils";
import validateFile from "../file-uploader/validateFile";
import isFormsFileType from "../utils/isFormsFileType";

type FileUploaderFormFieldsConfig = {
  shouldSkipFileHeaderValidation?: boolean;
};

export const createFileUploaderFormFieldsSchema = ({
  shouldSkipFileHeaderValidation = false,
}: FileUploaderFormFieldsConfig = {}) =>
  z
    .object({
      fileType: z.nativeEnum(FileType),
      // The Cosmic uploader expects an array of files.
      files: z.array(z.instanceof(File)),
      formTaxYear: fourDigitYearSchema.optional(),
      formDocumentType: dashboardFormTypeSchema.optional(),
      accountsTemplateType: z.nativeEnum(AccountsTemplateType).optional(),
      transactionsTemplateType: z
        .nativeEnum(TransactionsTemplateType)
        .optional(),
    })
    .superRefine(
      (
        {
          accountsTemplateType,
          fileType,
          formDocumentType,
          formTaxYear,
          transactionsTemplateType,
        },
        ctx
      ) => {
        if (isFormsFileType(fileType)) {
          if (!formTaxYear) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please select a form year.",
              path: ["formTaxYear"],
            });
          }
          if (!formDocumentType) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please select a form type.",
              path: ["formDocumentType"],
            });
          }
        } else if (fileType === FileType.Accounts && !accountsTemplateType) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please select a template.",
            path: ["accountsTemplateType"],
          });
        } else if (
          fileType === FileType.Transactions &&
          !transactionsTemplateType
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please select a template.",
            path: ["transactionsTemplateType"],
          });
        }
      }
    )
    .superRefine(
      // TODO: Upgrade zod to 3.22.2+ for a type fix on superRefine so we
      // can remove this eslint override.
      // https://github.com/colinhacks/zod/releases/tag/v3.22.2
      // https://taxbit.atlassian.net/browse/TAX-37426
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      async (
        {
          files: [rhfFile],
          fileType,
          formDocumentType,
          accountsTemplateType,
          transactionsTemplateType,
        },
        ctx
      ) => {
        const templateType = getTemplateTypeForFile({
          fileType,
          accountsTemplateType,
          formDocumentType,
          transactionsTemplateType,
        });

        const message = await validateFile({
          shouldSkipFileHeaderValidation,
          fileType,
          file: rhfFile,
          templateType,
        });

        if (message) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message,
            path: ["files"],
          });
        }
      }
    );
