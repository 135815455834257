import { HydratedAccount } from "@taxbit-dashboard/rest";

import { AccountsView } from "./useAccountsUrlParams";

export const VAT_STATUS_LABELS: Record<
  Required<HydratedAccount>["vatStatus"],
  string
> = {
  VALID: "Valid",
  PENDING: "Pending",
  INSUFFICIENT_DATA: "Insufficient Data",
  ERROR: "Error",
  INVALID: "Invalid",
  NOT_REQUIRED: "Not Required",
  UNHANDLED: "Unhandled",
};

export const TIN_STATUS_LABELS: Record<
  Required<HydratedAccount>["tinStatus"],
  string
> = {
  PENDING: "Pending",
  VALID_SSN_MATCH: "Valid SSN",
  VALID_EIN_MATCH: "Valid EIN",
  VALID_SSN_EIN_MATCH: "Valid SSN & EIN",
  FOREIGN: "Foreign",
  INVALID_DATA: "Invalid Data",
  MISMATCH: "Mismatch",
  TIN_NOT_ISSUED: "TIN Not Issued",
  ERROR: "Error",
  UNHANDLED: "Unhandled",
};

export const TAX_DOCUMENT_STATUS_LABELS: Record<
  Required<HydratedAccount>["taxDocumentStatus"],
  string
> = {
  VALID: "Valid",
  INVALID: "Invalid",
  UNDOCUMENTED: "Undocumented",
  UNDETERMINED: "Undetermined",
  ERROR: "Error",
};

export const TAX_CLASSIFICATION_LABELS: Record<
  Required<HydratedAccount>["taxClassification"],
  string
> = {
  US_IRA_ROTH: "US IRA Roth",
  US_IRA_TRADITIONAL: "US IRA Traditional",
  US_IRA_SEP: "US IRA SEP",
  US_IRA_SIMPLE: "US IRA Simple",
  INDIVIDUAL: "Individual",
  LLC_S: "LLC S",
  S_CORPORATION: "S Corporation",
  LLC_P: "LLC P",
  CORPORATION: "Corporation",
  LLC_C: "LLC C",
  C_CORPORATION: "C Corporation",
  OTHER: "Other",
  TRUST_ESTATE: "Trust Estate",
  PARTNERSHIP: "Partnership",
  INTERNATIONAL_ORGANIZATION: "International Organization",
  SOLE_PROPRIETOR: "Sole Proprietor",
  SIMPLE_TRUST: "Simple Trust",
  PRIVATE_FOUNDATION: "Private Foundation",
  COMPLEX_TRUST: "Complex Trust",
  ESTATE: "Estate",
  TAX_EXEMPT_ORGANIZATION: "Tax Exempt Organization",
  FOREIGN_GOVERNMENT_CONTROLLED_ENTITY: "Foreign Government Controlled Entity",
  FOREIGN_GOVERNMENT_INTEGRAL_PART: "Foreign Government Integral Part",
  CENTRAL_BANK_OF_ISSUE: "Central Bank of Issue",
  GRANTOR_TRUST: "Grantor Trust",
  UNHANDLED: "Unhandled",
};

export const ACCOUNTS_TABLE_VIEW_LABELS: Record<AccountsView, string> = {
  ALL: "View: All",
  US: "View: US",
  NON_US: "View: Non-US",
};
