import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  DispositionMethod,
  payerDispositionMethodLabelMap,
} from "@taxbit-dashboard/rest";
import { Body, Flex } from "@taxbit-private/cosmic";
import {
  RhfDropdown,
  RhfFormattedNumberInput,
  RhfTextInput,
} from "@taxbit-private/cosmic-react-hook-form";

import { AddressCountry, countryOptions } from "../../../utils/countryOptions";
import {
  FilerSetupBaseDetails,
  FilerSetupModalTrackingId,
} from "../filerSetupModalTypes";

const FilerBaseDetails: React.FC<{ isEditMode: boolean }> = ({
  isEditMode,
}) => {
  const dispositionMethodOptions: DispositionMethod[] = [
    DispositionMethod.EnterpriseExchangeHifo,
    DispositionMethod.EnterpriseExchangeFifo,
    DispositionMethod.EnterpriseExchangeLifo,
  ];

  const { hasPayerTimeOffsetUpdateAccess } = useDashboardFeatureFlags();

  return (
    <Flex direction="column" gap="l">
      <RhfTextInput<FilerSetupBaseDetails>
        label="Filer Name"
        name="filerName"
        trackingId={FilerSetupModalTrackingId.FilerName}
      />
      <RhfDropdown<FilerSetupBaseDetails, "taxCountry", AddressCountry>
        getOptionKey={(option) => option.code}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.code}
        label="Tax Country"
        isLabelHidden={false}
        name="taxCountry"
        options={countryOptions}
        trackingId={FilerSetupModalTrackingId.TaxCountryDropdown}
      />
      <Flex direction="column" gap="s">
        <RhfTextInput<FilerSetupBaseDetails>
          label="Employee Identification Number (EIN)"
          name="ein"
          trackingId={FilerSetupModalTrackingId.Ein}
          helpIconProps={{
            trackingId: FilerSetupModalTrackingId.EinHelpIcon,
            tooltipProps: {
              content:
                "An EIN is a unique tax identification number issued by the Internal Revenue Service (IRS) to a business entity, commonly used for the purpose of reporting tax information. The number is made up of nine digits and is formatted as XX-XXXXXXX.",
            },
          }}
        />
      </Flex>
      <RhfDropdown<
        FilerSetupBaseDetails,
        "dispositionMethod",
        DispositionMethod
      >
        getOptionKey={(value) => value}
        getOptionLabel={(value) => payerDispositionMethodLabelMap[value]}
        getOptionValue={(value) => value}
        label="Disposition Method"
        name="dispositionMethod"
        options={dispositionMethodOptions}
        trackingId={FilerSetupModalTrackingId.DispositionMethodDropdown}
        helpIconProps={{
          trackingId: FilerSetupModalTrackingId.DispositionMethodHelpIcon,
          tooltipProps: {
            content: (
              <>
                <Body>
                  Defines the order in which the various lots of a specific
                  asset are disposed of, which impacts the amount of gain/loss
                  recognized. The default value for this field is FIFO, First in
                  First Out.
                </Body>
                <Body>
                  Updating Disposition Method on an existing Filer is not
                  permitted. A new Filer must be created.
                </Body>
              </>
            ),
          },
        }}
        isDisabled={isEditMode}
      />
      {hasPayerTimeOffsetUpdateAccess && (
        <RhfFormattedNumberInput<FilerSetupBaseDetails>
          label="Time Offset"
          name="timeOffset"
          trackingId={FilerSetupModalTrackingId.TimeOffset}
          valueOutputFormat="number"
          format="WholeQuantity"
        />
      )}
    </Flex>
  );
};
export default FilerBaseDetails;
