import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const POSSIBLE_TIN_STATUSES = [
  "PENDING",
  "FOREIGN",
  "INVALID_DATA",
  "VALID_SSN_MATCH",
  "VALID_EIN_MATCH",
  "VALID_SSN_EIN_MATCH",
  "MISMATCH",
  "TIN_NOT_ISSUED",
  "ERROR",
  "UNHANDLED",
] as const;
const tinStatusSchema = z.enum(POSSIBLE_TIN_STATUSES).catch("UNHANDLED");

export const POSSIBLE_VAT_STATUSES = [
  "PENDING",
  "VALID",
  "INVALID",
  "INSUFFICIENT_DATA",
  "NOT_REQUIRED",
  "ERROR",
  "UNHANDLED",
] as const;
const vatStatusSchema = z.enum(POSSIBLE_VAT_STATUSES).catch("UNHANDLED");

export const POSSIBLE_TAX_DOCUMENT_STATUSES = [
  "VALID",
  "INVALID",
  "UNDOCUMENTED",
  "UNDETERMINED",
  "ERROR",
] as const;
const taxDocumentStatusSchema = z
  .enum(POSSIBLE_TAX_DOCUMENT_STATUSES)
  .catch("ERROR");

export const POSSIBLE_TAX_CLASSIFICATIONS = [
  "US_IRA_ROTH",
  "US_IRA_TRADITIONAL",
  "US_IRA_SEP",
  "US_IRA_SIMPLE",
  "INDIVIDUAL",
  "LLC_S",
  "S_CORPORATION",
  "LLC_P",
  "CORPORATION",
  "LLC_C",
  "C_CORPORATION",
  "OTHER",
  "TRUST_ESTATE",
  "PARTNERSHIP",
  "INTERNATIONAL_ORGANIZATION",
  "SOLE_PROPRIETOR",
  "SIMPLE_TRUST",
  "PRIVATE_FOUNDATION",
  "COMPLEX_TRUST",
  "ESTATE",
  "TAX_EXEMPT_ORGANIZATION",
  "FOREIGN_GOVERNMENT_CONTROLLED_ENTITY",
  "FOREIGN_GOVERNMENT_INTEGRAL_PART",
  "CENTRAL_BANK_OF_ISSUE",
  "GRANTOR_TRUST",
  "UNHANDLED",
] as const;
const taxClassificationSchema = z
  .enum(POSSIBLE_TAX_CLASSIFICATIONS)
  .catch("UNHANDLED");

const hydratedAccountSchema = z.object({
  id: uuidSchema,
  organizationId: uuidSchema,
  dateCreated: z.string(),
  name: z.string().optional(),
  externalId: z.string(),
  email: z.string().optional(),
  taxClassification: taxClassificationSchema.optional(),
  tinStatus: tinStatusSchema.optional(),
  vatStatus: vatStatusSchema.optional(),
  taxDocumentStatus: taxDocumentStatusSchema.optional(),
  taxDocumentTypes: z.array(z.string()).optional(),
  taxCountryCodes: z.array(z.string()).optional(),
});

export type HydratedAccount = z.infer<typeof hydratedAccountSchema>;

export const getHydratedAccountsResponse = getPublicApiSuccessSchema(
  z.array(hydratedAccountSchema)
);

export const getHydratedAccountsParamsSchema = z.object({
  page: z.number(),
  limit: z.number(),
});

export type GetHydratedAccountsParams = z.infer<
  typeof getHydratedAccountsParamsSchema
>;
