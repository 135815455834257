import { Box, Table, TrimmedBoxContent } from "@taxbit-private/cosmic";

import AccountsTablePagination from "./AccountsTablePagination";
import { useAccountsTable } from "./useAccountsTable";

export enum AccountsTableTrackingId {
  TableContent = "accounts-table-content",
}

export const AccountsTable: React.FC = () => {
  const { tableProps, shouldDisableControls, totalCount } = useAccountsTable();
  return (
    <Box trackingId={AccountsTableTrackingId.TableContent}>
      <TrimmedBoxContent trim="all">
        <Table {...tableProps} />
        <AccountsTablePagination
          isDisabled={shouldDisableControls}
          totalCount={totalCount}
        />
      </TrimmedBoxContent>
    </Box>
  );
};
