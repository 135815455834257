import { logError, renderExcludedSpan } from "@taxbit-dashboard/commons";
import { HydratedAccount } from "@taxbit-dashboard/rest";
import { Badge, TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import { assertUnreachable } from "../../../../utils/assertUnreachable";
import {
  VAT_STATUS_LABELS,
  TIN_STATUS_LABELS,
  TAX_CLASSIFICATION_LABELS,
} from "../accountsLabelMaps";
import { AccountsView, useAccountsUrlParams } from "../useAccountsUrlParams";

const logUnknown = (value: string, field: string) =>
  logError({
    message: `Table Data Error: Unknown value ${value} for field ${field}`,
  });

const renderBadge = (
  label: string,
  variant: "success" | "danger" | "warning" | "secondary"
) => <Badge shouldTransformLabel={false} variant={variant} label={label} />;

const renderStringArray = (data?: string[]) => data && data.sort().join(", ");

const renderVatStatus = (vatStatus: HydratedAccount["vatStatus"]) => {
  if (!vatStatus) return vatStatus;

  const label = VAT_STATUS_LABELS[vatStatus];

  switch (vatStatus) {
    case "VALID": {
      return renderBadge(label, "success");
    }
    case "PENDING":
    case "NOT_REQUIRED": {
      return renderBadge(label, "secondary");
    }
    case "INSUFFICIENT_DATA":
    case "ERROR":
    case "INVALID": {
      return renderBadge(label, "danger");
    }
    case "UNHANDLED": {
      logUnknown(vatStatus, "vatStatus");
      return undefined;
    }
    default: {
      return assertUnreachable(vatStatus);
    }
  }
};

const renderTinStatus = (tinStatus: HydratedAccount["tinStatus"]) => {
  if (!tinStatus) return tinStatus;

  const label = TIN_STATUS_LABELS[tinStatus];

  switch (tinStatus) {
    case "VALID_SSN_MATCH":
    case "VALID_EIN_MATCH":
    case "VALID_SSN_EIN_MATCH": {
      return renderBadge(label, "success");
    }
    case "PENDING":
    case "FOREIGN": {
      return renderBadge(label, "secondary");
    }
    case "INVALID_DATA":
    case "MISMATCH":
    case "ERROR":
    case "TIN_NOT_ISSUED": {
      return renderBadge(label, "danger");
    }
    case "UNHANDLED": {
      logUnknown(tinStatus, "tinStatus");
      return undefined;
    }
    default: {
      return assertUnreachable(tinStatus);
    }
  }
};

const renderTaxClassification = (
  taxClassification: HydratedAccount["taxClassification"]
) => {
  if (!taxClassification) return taxClassification;
  return TAX_CLASSIFICATION_LABELS[taxClassification];
};

const renderTaxDocumentStatus = (
  taxDocumentStatus: HydratedAccount["taxDocumentStatus"]
) => {
  if (!taxDocumentStatus) return taxDocumentStatus;
  switch (taxDocumentStatus) {
    case "VALID": {
      return renderBadge("Valid", "success");
    }
    case "INVALID": {
      return renderBadge("Invalid", "danger");
    }
    case "UNDOCUMENTED":
    case "UNDETERMINED": {
      return renderBadge("Undetermined", "secondary");
    }
    case "ERROR": {
      return renderBadge("Error", "danger");
    }
    default: {
      logUnknown(taxDocumentStatus, "taxDocumentStatus");
      return undefined;
    }
  }
};

export const VIEW_COLUMNS: Record<AccountsView, (keyof HydratedAccount)[]> = {
  ALL: [
    "externalId",
    "name",
    "dateCreated",
    "email",
    "taxClassification",
    "taxCountryCodes",
    "vatStatus",
    "tinStatus",
    "taxDocumentTypes",
    "taxDocumentStatus",
  ],
  US: [
    "externalId",
    "name",
    "dateCreated",
    "email",
    "taxClassification",
    "taxCountryCodes",
    "tinStatus",
    "taxDocumentTypes",
    "taxDocumentStatus",
  ],
  NON_US: [
    "externalId",
    "name",
    "dateCreated",
    "email",
    "taxClassification",
    "taxCountryCodes",
    "vatStatus",
    "taxDocumentTypes",
    "taxDocumentStatus",
  ],
};

export const useAccountsTableColumns = () => {
  const {
    urlParams: { view },
  } = useAccountsUrlParams();
  const { formatDateTime } = useCosmicLocalizationContext();

  const visibleColumns = useMemo(() => VIEW_COLUMNS[view], [view]);

  const columns = useMemo(() => {
    const allColumns: TableColumns<HydratedAccount> = [
      {
        key: "externalId",
        label: "account id",
        shouldDisableSorting: true,
        shouldTruncate: true,
        // 261px = approximately 33 chars, the average length.
        minWidth: 261,
        renderCell: renderExcludedSpan,
      },
      {
        key: "name",
        label: "name",
        shouldTruncate: true,
        minWidth: 128,
        renderCell: renderExcludedSpan,
      },
      {
        key: "dateCreated",
        label: "account created",
        renderCell: (date) =>
          formatDateTime({
            date,
          }),
      },
      {
        key: "email",
        label: "email",
        shouldDisableSorting: true,
        shouldTruncate: true,
        minWidth: 180,
        renderCell: renderExcludedSpan,
      },
      {
        key: "taxClassification",
        label: "tax class.",
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderTaxClassification,
      },
      {
        key: "taxCountryCodes",
        label: "tax country code",
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderStringArray,
      },
      {
        key: "vatStatus",
        label: "vat status",
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderVatStatus,
      },
      {
        key: "tinStatus",
        label: "us tin status",
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderTinStatus,
      },
      {
        key: "taxDocumentTypes",
        label: "doc. type",
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderStringArray,
      },
      {
        key: "taxDocumentStatus",
        label: "doc. status",
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderTaxDocumentStatus,
      },
    ];
    const filteredColumns: TableColumns<HydratedAccount> = allColumns.filter(
      (c) => c?.key && visibleColumns.includes(c.key)
    );
    return filteredColumns;
  }, [formatDateTime, visibleColumns]);

  return columns;
};
