import { z } from "zod";

import { organizationIdSchema, payerIdSchema } from "./sharedApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

const addressSchema = z.object({
  firstLine: z.string().optional(),
  secondLine: z.string().optional(),
  city: z.string().optional(),
  stateOrProvince: z.string().optional(),
  postalCode: z.string().optional(),
  country: z.string().optional(),
});

export type PayerAddress = z.infer<typeof addressSchema>;

export enum DispositionMethod {
  EnterpriseExchangeHifo = "ENTERPRISE_EXCHANGE_HIFO",
  EnterpriseExchangeFifo = "ENTERPRISE_EXCHANGE_FIFO",
  EnterpriseExchangeLifo = "ENTERPRISE_EXCHANGE_LIFO",
  UniversalFifo = "UNIVERSAL_FIFO",
  UniversalHifo = "UNIVERSAL_HIFO",
  ExchangeHifo = "EXCHANGE_HIFO",
  GbIndividual = "GB_INDIVIDUAL",
  GbBusiness = "GB_BUSINESS",
  LuWac = "LU_WAC",
}

export const dispositionMethodSchema = z.nativeEnum(DispositionMethod);

export const dispositionMethodOverridesSchema = z.record(
  z.string(),
  dispositionMethodSchema
);

export const payerSchema = z.object({
  payerId: payerIdSchema,
  dateModified: z.string(),
  dateCreated: z.string(),
  organizationId: organizationIdSchema,
  address: addressSchema,
  ein: z.string(),
  // TODO: Add Non-US Tax ID info here https://taxbit.atlassian.net/browse/TAX-38167
  taxCountryCode: z.string().optional(),
  logoUrl: z.string().optional(),
  payerName: z.string(),
  phone: z.string(),
  dispositionMethodOverrides: dispositionMethodOverridesSchema.optional(),
  dispositionMethod: dispositionMethodSchema,
  isDefault: z.boolean(),
  gmtOffsetMinutes: z.number(),
});

export type Payer = z.infer<typeof payerSchema>;

export const payersSchema = z.array(payerSchema);

export type Payers = z.infer<typeof payersSchema>;

export const getPayersResponseSchema = getPublicApiSuccessSchema(payersSchema);

export type GetPayersResponse = z.infer<typeof getPayersResponseSchema>;

export const createPayerSchema = z.object({
  address: addressSchema,
  ein: z.string(),
  taxCountryCode: z.string(),
  // TODO: Add Non-US Tax ID info here https://taxbit.atlassian.net/browse/TAX-38167
  payerName: z.string(),
  phone: z.string(),
  dispositionMethodOverrides: dispositionMethodOverridesSchema.optional(),
  dispositionMethod: dispositionMethodSchema,
  gmtOffsetMinutes: z.number(),
});

export type CreatePayer = z.infer<typeof createPayerSchema>;

export const createPayerMultipartFormSchema = z
  .object({
    data: createPayerSchema,
    logo: z.instanceof(File).optional(),
  })
  .transform((data) => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data.data));
    if (data.logo) {
      formData.append("logo", data.logo);
    }
    return formData;
  })
  .brand("CreatePayerFormData");

export type CreatePayerMultipartForm = z.infer<
  typeof createPayerMultipartFormSchema
>;

export type CreatePayerMultipartFormInput = z.input<
  typeof createPayerMultipartFormSchema
>;

export const updatePayerSchema = createPayerSchema
  .omit({ dispositionMethod: true })
  .partial()
  .extend({
    isDefault: z.boolean().optional(),
  });

export type UpdatePayer = z.infer<typeof updatePayerSchema>;

export const updatePayerMultipartFormSchema = z
  .object({
    data: updatePayerSchema,
    logo: z.instanceof(File).optional(),
  })
  .transform((data) => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data.data));
    if (data.logo) {
      formData.append("logo", data.logo);
    }
    return formData;
  })
  .brand("UpdatePayerFormData");

export type UpdatePayerMultipartForm = z.infer<
  typeof updatePayerMultipartFormSchema
>;

export type UpdatePayerMultipartFormInput = z.input<
  typeof updatePayerMultipartFormSchema
>;

export const getPayerResponseSchema = getPublicApiSuccessSchema(payerSchema);
