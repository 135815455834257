import { FileAction, FileType } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import RhfFileTypeSelector from "./select-file-type-step/RhfFileTypeSelector";
import AccountsTemplateStep from "./template-step/AccountsTemplateStep";
import FormDataTemplateStep from "./template-step/FormDataTemplateStep";
import TransactionsTemplateStep from "./template-step/TransactionsTemplateStep";
import UploadStep from "./upload-step/UploadStep";
import useFileUploaderContext from "../context/useFileUploaderContext";

const getSelectFileTypeStep = (action: FileAction) =>
  ({
    title: "Select File Type",
    content: <RhfFileTypeSelector action={action} />,
    key: "select-file-type",
  }) as const;

const useFileUploaderFormSteps = ({ action }: { action: FileAction }) => {
  const { selectedFileType, hasMultipleTemplatesForSelectedFileType } =
    useFileUploaderContext(action);

  const templateStepArray = useMemo(() => {
    const templateElement = (() => {
      switch (selectedFileType) {
        case FileType.Accounts: {
          return <AccountsTemplateStep />;
        }
        case FileType.Forms:
        case FileType.DeleteForms: {
          return <FormDataTemplateStep action={action} />;
        }
        case FileType.Transactions: {
          return <TransactionsTemplateStep />;
        }
        default: {
          return undefined;
        }
      }
    })();

    if (templateElement && hasMultipleTemplatesForSelectedFileType) {
      return [
        {
          title: "Select Template",
          content: templateElement,
          key: "select-template",
        },
      ];
    } else {
      return [];
    }
  }, [action, hasMultipleTemplatesForSelectedFileType, selectedFileType]);

  return [
    getSelectFileTypeStep(action),
    ...templateStepArray,
    {
      title: "Upload File",
      content: <UploadStep action={action} />,
      key: "upload-file",
    },
  ];
};

export default useFileUploaderFormSteps;
